
<template>
  <div>
    <CRow>
      <CCol sm>
        <CCard>
          <CCardBody>
            <p class="titre">{{ $t("configuation") }}</p>
            <form @submit.prevent="saveScreen" id="formscreen">
              <CInput
                :label="this.$t('screen_name')"
                :placeholder="this.$t('screen_name_placeholder')"
                v-model="screen.name"
                :invalid-feedback="this.$t('name_required')"
                :is-valid="checkName"
              ></CInput>

              <div class="form-group">
                <label for="timer_screen">{{ $t("timer") }}</label>
                <input
                  v-model="screen.timer"
                  id="timer_screen"
                  name="timer"
                  type="number"
                  step="0.5"
                  min="0.5"
                  class="form-control"
                />
              </div>

              <CTextarea
                label="Bandeau"
                placeholder="Bandeau de bas d'écran"
                v-model="screen.bandeau"
              ></CTextarea>
              <!--div role="group" class="form-group" data-children-count="1">
                  <div class="d-flex justify-content-between">
                    <label for="uid-mqjopxcj4ef" class="">En diffusion</label>
                    <CSwitch
                      variant="3d"
                      color="success"
                      :checked.sync="screen.online"
                    />
                  </div>
                </div-->
              <div class="d-flex justify-content-between">
                <div class="text-danger">{{ errors.message }}</div>
                <div v-if="!loading" class="text-right">
                  <button class="btn btn-primary" type="submit">
                    {{ $t("save") }}
                  </button>
                </div>
                <div v-else class="text-right">
                  <button class="btn btn-primary" type="submit" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ $t("saving") }}
                  </button>
                </div>
              </div>
              <CAlert :show.sync="successSaved" color="success" fade>{{
                $t("save_success")
              }}</CAlert>
            </form>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm>
        <CCard>
          <CCardBody>
            <p class="titre">{{ $t("diff") }}</p>
            <div class="form-group">
              <label for="">{{ $t("url_diffusion") }}</label>
              <div class="d-flex">
                <input
                  v-model="urlDiff"
                  disabled
                  class="form-control urlDiff"
                />
                <button class="btn btn-light" @click="copyURL">
                  <i class="small material-icons">content_copy</i>
                </button>
              </div>
              <div class="calert_div">
                <CAlert :show.sync="urlCopied" color="success" fade>{{
                  $t("copied")
                }}</CAlert>
              </div>
            </div>

            <div class="form-group">
              <label for="">{{ $t("global_video") }}</label>
              <span
                v-if="
                  screen.fullvideo_link_sh && screen.fullvideo_status == 'done'
                "
              >
                <div class="d-flex">
                  <input
                    v-model="urlVidFull"
                    disabled
                    class="form-control urlVidFull"
                  />
                  <button class="btn btn-light" @click="copyURLvideo">
                    <i class="small material-icons">content_copy</i>
                  </button>
                </div>
                <div class="calert_div">
                  <CAlert :show.sync="urlVideoCopied" color="success" fade>{{
                    $t("copied")
                  }}</CAlert>
                </div>
              </span>
              <span v-else
                ><p>Statut : {{ $t(screen.fullvideo_status) }}</p></span
              >
              <p
                v-if="
                  (screen.fullvideo_status == 'done' ||
                    screen.fullvideo_status == 'none') &&
                  showGButton
                "
              >
                <button
                  class="btn btn-primary btn_generate_video"
                  @click="generateVideo"
                >
                  {{ $t("generate_video") }}
                </button>
              </p>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow v-if="screen.medias" class="column_medias">
      <CCol>
        <CCard>
          <CCardBody>
            <p class="titreMediaEcran">{{ $t("media_on_screen") }}</p>
            <p class="sousTitreMediaEcran">
              {{ $t("sous_titre_media_on_screen") }}
            </p>
            <GalerieScreen
              v-bind:medias="screen.medias"
              :screen_id="screen.id"
              :key="reloadGalerieScreen"
            ></GalerieScreen>
            <div class="text-right">
              <button class="btn btn-primary" @click="popupGalerie = true">
                {{ $t("add_media") }}
              </button>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow v-if="popupGalerie">
      <Galerie
        @closeGalerie="closePopupGalerie"
        :screen_id="screen.id"
      ></Galerie>
    </CRow>
  </div>
</template>

<script>
import GalerieScreen from "@/components/GalerieScreen.vue";
import Galerie from "@/components/Galerie.vue";

export default {
  name: "Screen",
  components: {
    GalerieScreen,
    Galerie,
  },
  data() {
    return {
      id: this.$route.params.id,
      screen: "",
      loading: false,
      errors: { message: "" },
      isActive: true,
      successSaved: 0,
      popupGalerie: false,
      reloadGalerieScreen: 1,
      globalURL: process.env.VUE_APP_MEDIA_URL,
      apiURL: process.env.VUE_APP_API_URL,
      urlCopied: 0,
      urlVideoCopied: 0,
      showGButton: true,
    };
  },
  computed: {
    urlDiff: function () {
      return this.apiURL + "/rbpi/" + this.screen.id_rasb;
    },
    urlVidFull: function () {
      return (
        this.apiURL +
        "/images/" +
        this.$store.state.user.id +
        "/" +
        this.screen.id_rasb +
        ".mp4"
      );
    },
  },
  async created() {
    console.log("created before");
    let rep = await window.axios.get("/api/screen/" + this.id);
    this.screen = rep.data.screen;

    /*window.axios.get("/api/screen/" + id_screen).then((rep) => {
      self.screen = rep.data.screen;
      console.log('done')
      console.log(self.screen);
      self.loading = false
    })
    .catch(error =>{
      console.log(error)
      self.loading = false
    })
    .finally(()=>{
      self.loading = false
      console.log('finnaly', this.loading)
    })*/
  },
  methods: {
    async copyURL() {
      const myText = this.urlDiff;
      await navigator.clipboard.writeText(myText);
      this.urlCopied = 1;
    },
    async copyURLvideo() {
      const myText = this.urlVidFull;
      await navigator.clipboard.writeText(myText);
      this.urlVideoCopied = 1;
    },
    async closePopupGalerie() {
      this.popupGalerie = false;
      //this.$forceUpdate()
      let rep = await window.axios.get("/api/screen/" + this.id);
      this.screen = rep.data.screen;
      this.reloadGalerieScreen++;
    },

    async saveScreen() {
      console.log("save...");
      this.loading = true;
      try {
        let rep = await window.axios.put("/api/screen/" + this.id, this.screen);
        this.screen = { ...this.screen, ...rep.data.screen };
        this.successSaved = 1;
        this.errors.message = "";
      } catch (err) {
        console.log(err.response.data);
        this.errors = err.response.data;
      } finally {
        this.loading = false;
      }
    },
    checkName() {
      return this.screen.name == "" ? false : true;
    },
    async generateVideo() {
      console.log("generate");
      this.showGButton = false;
      try {
        let rep = await window.axios.post(
          "/api/screen/" + this.id + "/generatefullvideo"
        );
        this.screen.fullvideo_status = "ongoing";
        console.log(rep);
      } catch (err) {
        console.log(err);
        this.showGButton = true;
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 2rem;
}
.form-control.is-valid {
  border-color: #d8dbe0 !important;
  background-image: none !important;
}
.titre{
  font-size: 20px;
}
.titreMediaEcran {
  margin-left: 15px;
  
  font-size: 20px;
}

.sousTitreMediaEcran {
  margin-top: -15px;
  margin-left: 15px;
  font-size: 12px;
  font-style: italic;
}

.urlDiff,
.urlVidFull {
  max-width: 400px;
  margin-right: 10px;
}
.calert_div {
  margin-top: 8px;
  max-width: 400px;
}

.btn_generate_video {
  margin-top: 15px;
}

.column_medias {
  flex-direction: column;
}
</style>
